.App {
  height: 100vh;
  width: 100vw;
}
#app {
  height: 100vh;
  width: 100vw;
}
#map {
  height: 100%;
  width: 67%;
}
.leg {
  position: absolute;
  bottom: 20px;
  right: 35.5vw;
  z-index: 1000;
}
