.toolbox {
    margin: 5px 5px 5px 5px;
    height: 98%;
    width: 32%;
    max-width: 33%;
    float: right;
}

.timeline {
    height: 68%;
}
