.sidebar-close-btn {
  position: absolute;
  top: 610px;
  right: 610px;
  z-index: 100;
}
.basemapTitle {
  align-items: center;
  margin-bottom: 2px;
  padding-bottom: 2px;
  margin-left: 35px;
}
