@import url("~leaflet/dist/leaflet.css");
@import url("~leaflet-draw/dist/leaflet.draw.css");

body,
#root,
.leaflet-container {
    position: relative;
    height: 100%;
    width: 100%;
}
